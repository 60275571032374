import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { unsubscribeUserAPI } from '../../util/api'; // Import API function

// ================ Action types ================ //
export const UNSUBSCRIBE_REQUEST = 'app/UnsubscribePage/UNSUBSCRIBE_REQUEST';
export const UNSUBSCRIBE_SUCCESS = 'app/UnsubscribePage/UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_ERROR = 'app/UnsubscribePage/UNSUBSCRIBE_ERROR';

// ================ Reducer ================ //

const initialState = {
    emailUnsubscribed: false,
    queryInProgress: false,
    queryError: null,
};

const unsubscribePageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case UNSUBSCRIBE_REQUEST:
            return { ...state, queryInProgress: true, queryError: null, unsubscribe: false };

        case UNSUBSCRIBE_SUCCESS:
            return { ...state, queryInProgress: false, emailUnsubscribed: payload.emailUnsubscribed };

        case UNSUBSCRIBE_ERROR:
            return { ...state, queryInProgress: false, queryError: payload };

        default:
            return state;
    }
};
export default unsubscribePageReducer;


// ================ Action creators ================ //

export const unsubscribeRequest = () => ({
    type: UNSUBSCRIBE_REQUEST,
});

export const unsubscribeSuccess = payload => ({
    type: UNSUBSCRIBE_SUCCESS,
    payload
});

export const unsubscribeError = error => ({
    type: UNSUBSCRIBE_ERROR,
    payload: error,
    error: true,
});


// ================ Thunks ================ //

export const unsubscribeUser = payload => async dispatch => {
    dispatch(unsubscribeRequest());

    try {
        const response = await unsubscribeUserAPI(payload); // Call API
        if (response && response.data && response.data.data.attributes.profile.protectedData) {
            const { emailUnsubscribed = false } = response.data.data.attributes.profile.protectedData;
            dispatch(unsubscribeSuccess({ emailUnsubscribed }));
        }
        return response;
    } catch (error) {
        console.error("Error unsubscribing user:", error);
        dispatch(unsubscribeError(storableError(error)));
    }
};

export const loadData = (params, search) => dispatch => {
    // const queryParams = parse(search);
    // const { token } = queryParams;

    return Promise.all({});
};