import { parse } from '../../util/urlHelpers';
import { storableError } from '../../util/errors';
import { getListings, matchAlgorithm, searchAlgoliaData } from '../../util/api';
import { updatedEntities } from '../../util/data';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';

import { fetchCurrentUser } from '../../ducks/user.duck';
import { addMarketplaceEntities, getOwnListingsById } from '../../ducks/marketplaceData.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 100;

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ProviderDashBoardPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ProviderDashBoardPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ProviderDashBoardPage/FETCH_LISTINGS_ERROR';

export const FETCH_REVIEWS_SUCCESS = 'app/ProviderDashBoardPage/FETCH_REVIEWS_SUCCESS';
export const LOAD_LISTING_ID_TRANSACTION = 'app/ProviderDashBoardPage/LOAD_LISTING_ID_TRANSACTION';

export const ADD_OWN_ENTITIES = 'app/ProviderDashBoardPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  ownEntities: {},
  allRFpLength: 0,
  bidRfpLength: 0,
  acceptRfpLength: 0,
  allTransactions: [],
  allUserDistances: [],
  allUserReviews: [],
  loadListingIdTransaction: null,
  openBidIds: [],
  txIds: [],
};

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const ProviderDashBoardPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        ...payload,
        // pagination: payload.pagination,
        queryInProgress: false,
        queryListingsError: null,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state, queryInProgress: false, allUserReviews: payload
      };
    case LOAD_LISTING_ID_TRANSACTION:
      return {
        ...state,
        loadListingIdTransaction: payload
      };
    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
};

export default ProviderDashBoardPageReducer;

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsSuccess = response => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: response,
});

export const loadListingIdTransactionRequest = queryParams => ({
  type: LOAD_LISTING_ID_TRANSACTION,
  payload: queryParams
});

const getImageParams = (search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return {
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    pub_typeOfListing: "event",
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  }
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const ownlistingParams = getImageParams(search, config);
  const { bidType = 'strong', pub_city } = ownlistingParams || {};

  return dispatch(fetchCurrentUser())
    .then(currentUser => {
      const { publicData: uPublicData, protectedData } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
      const { businessListingId } = protectedData || {};
      const { zipCodeRadius } = uPublicData || {};
      const businessListing = getOwnListingsById(getState(), [new UUID(businessListingId)]);

      if (businessListing && businessListing.length) {
        dispatch(queryListingsRequest(ownlistingParams));

        const { geolocation, publicData } = (businessListing[0] && businessListing[0].id && businessListing[0].attributes) || {};
        const { boothType, boothTypeChilds, backDropPackages } = publicData || {};
        // Array.isArray(boothType) && boothType.push('other');

        // const body = {
        //   querySearch: '',
        //   filters: {
        //     hitsPerPage: 20000,
        //     getRankingInfo: true,
        //     aroundLatLng: `${geolocation.lat}, ${geolocation.lng}`,
        //     aroundRadius: (['perfect', 'strong'].includes(bidType) ? 241401 : 6371000),
        //   },
        //   indexName: process.env.REACT_APP_ALGOLIA_EVENT_LISTING_INDEX,
        // };

        // if (['perfect', 'strong'].includes(bidType)) {
        //   const boothTypeFilter = boothType && boothType.length && !boothType.includes('other')
        //     ? boothType.map((st) => `boothType:"${st}"`).join(bidType == 'perfect' ? " AND " : " OR ")
        //     : '';
        //   const boothTypeChildsFilter = boothType && boothType.length && !boothType.includes('other')
        //     ? boothType.map((st, i) => Array.isArray(boothTypeChilds[st]) && boothTypeChilds[st].map((child, ind) => `${ind == 0 && i == 0 ? '(' : ''}boothTypeChilds.${st}:"${child}"${(boothType.length - 1) == i && (boothTypeChilds[st].length - 1) == ind ? ')' : ''}`).filter(d => d).join(" OR ")).join(bidType == 'perfect' ? ") AND (" : " OR ")
        //     : '';
        //   const equippmentFilter = boothType && boothType.length && !boothType.includes('other')
        //     ? boothType.map((st, i) => Array.isArray(boothTypeChilds[st + 'Equipment']) && boothTypeChilds[st + 'Equipment'].map((child, ind) => `${ind == 0 && i == 0 ? '(' : ''}boothTypeChilds.${st + 'Equipment'}:"${child}"${(boothType.length - 1) == i && (boothTypeChilds[st + 'Equipment'].length - 1) == ind ? ')' : ''}`).filter(d => d).join(" OR ")).join(bidType == 'perfect' ? ") AND (" : " OR ")
        //     : '';
        //   const backdropFilter = boothType && boothType.length && !boothType.includes('other')
        //     ? boothType.map((st, i) => ['photoBoothCompanyToProvideBackdrop', 'clientProvidingBackdrop', 'customBackdrop'].map((child, ind) => `${ind == 0 && i == 0 ? '(' : ''}boothTypeChilds.${st + 'backdropPreference'}:"${child}"${(boothType.length - 1) == i && ind == 2 ? ')' : ''}`).filter(d => d).join(" OR ")).join(bidType == 'perfect' ? ") AND (" : " OR ")
        //     : '';

        //   if (boothTypeFilter) {
        //     Object.assign(body.filters, {
        //       filters: boothTypeFilter
        //     });
        //     if (boothTypeChildsFilter) {
        //       Object.assign(body.filters, { filters: body.filters.filters + (bidType == 'perfect' ? " AND " : " OR ") + boothTypeChildsFilter });
        //     }
        //     if (equippmentFilter) {
        //       Object.assign(body.filters, { filters: body.filters.filters + (bidType == 'perfect' ? " AND " : " OR ") + equippmentFilter });
        //     }
        //     if (backdropFilter && bidType == 'perfect' && backDropPackages == 'yes') {
        //       Object.assign(body.filters, { filters: body.filters.filters + " AND " + backdropFilter });
        //     }
        //   }
        // }

        // if (body.filters.filters) {
        //   Object.assign(body.filters, { filters: body.filters.filters + " AND " + 'state:"published"' });
        // } else {
        //   Object.assign(body.filters, { filters: 'state:"published"' });
        // }

        const filterZipCodeRadius = zipCodeRadius && zipCodeRadius.length
          ? zipCodeRadius.filter(z => z.origin)
          : [];

        if (filterZipCodeRadius && filterZipCodeRadius.length) {
          zipCodeRadius.map(zipcode => {
            const { origin, serviceRadius, isDeleted } = zipcode;
            // Object.assign(body.filters, { aroundLatLng: `${origin.lat}, ${origin.lng}`, });
            // console.log(body, '**** **** => body');

            if (!isDeleted && origin) {
              // searchAlgoliaData(body)
              matchAlgorithm({
                query: {
                  boothTypes: boothType,
                  boothTypeChilds,
                  geoLoc: {
                    lat: origin.lat,
                    lng: origin.lng
                  },
                },
                type: bidType,
              })
                .then(res => {
                  // console.log(res.data, '**** **** => res.data');
                  const { businesses = [] } = res.data;
                  const algoliaResults = currentUser && currentUser.id
                    ? businesses.filter(hit => hit.userId != currentUser.id.uuid)
                    : businesses;

                  const events = algoliaResults.filter(hit => {
                    const { distance } = hit;
                    // const { geoDistance } = _rankingInfo || {};

                    return ['perfect', 'strong'].includes(bidType)
                      ? (+serviceRadius) >= (distance)
                      : distance;
                  });

                  const events20MilesExtra = algoliaResults.filter(hit => {
                    const { distance } = hit;
                    // const { geoDistance } = _rankingInfo || {};

                    return ['perfect'].includes(bidType)
                      ? (+serviceRadius) >= distance
                      : ['strong'].includes(bidType)
                        ? ((+serviceRadius) + 32186) >= (distance)
                        : distance;
                  });

                  const events50MilesExtra = algoliaResults.filter(hit => {
                    const { distance } = hit;
                    // const { geoDistance } = _rankingInfo || {};

                    return ['perfect'].includes(bidType)
                      ? (+serviceRadius) >= distance
                      : ['strong'].includes(bidType)
                        ? ((+serviceRadius) + 80467) >= (distance)
                        : distance;
                  });

                  const eventsShouldBe = events && events.length > 5
                    ? events
                    : events20MilesExtra && events20MilesExtra.length > 5
                      ? events20MilesExtra
                      : events50MilesExtra;

                  if (eventsShouldBe && eventsShouldBe.length) {
                    const listingIds = eventsShouldBe.map(event => event.id).join(',');
                    const listingParams = {
                      ids: listingIds,
                      include: ['author'],
                    };

                    if (pub_city) {
                      Object.assign(listingParams, { pub_city });
                    }

                    return sdk.listings
                      .query(listingParams)
                      .then(res => {
                        const { data: listings } = res.data;
                        listings.map(listing => {
                          const filteredEvent = eventsShouldBe.find(event => listing.id.uuid == event.id);
                          if (filteredEvent && filteredEvent.distance) {
                            Object.assign(listing.attributes.publicData, {
                              distanceMile: parseInt((filteredEvent.distance / 1000) * 0.621371)
                            });
                          }
                        })
                        dispatch(addMarketplaceEntities(res));
                        return dispatch(queryListingsSuccess({ openBidIds: listings.map(f => f.id) }));
                      });
                  }

                  return dispatch(queryListingsSuccess({ openBidIds: [] }));
                });
            }
          });
        } else {
          dispatch(queryListingsSuccess({ openBidIds: [] }));
        }

        try {
          searchAlgoliaData({
            querySearch: '',
            filters: {
              facetFilters: ['providerId:' + currentUser.id.uuid]
            },
            indexName: process.env.REACT_APP_ALGOLIA_TRANSACTIONS_INDEX,
          })
            .then(res => {
              const { hits: transactions } = res.data;
              if (transactions && transactions.length) {
                const listingIds = transactions.map(transaction => transaction.eventListingId).join(',');
                return getListings({ ids: listingIds })
                  .then(res => {
                    const { data: events } = res.data;
                    transactions.map(transaction => {
                      const index = events.findIndex(d => d.id.uuid == transaction.eventListingId);
                      if (index > -1) {
                        Object.assign(res.data.data[index].attributes, { transaction });
                        if (transaction.acceptedTxId) {
                          sdk.reviews.query({ transactionId: new UUID(transaction.acceptedTxId) })
                            .then(({ data }) => {
                              Object.assign(res.data.data[index].attributes, { reviews: data.data });
                              dispatch(addMarketplaceEntities(res));
                            });
                        }
                        dispatch(addMarketplaceEntities(res));
                        return dispatch(queryListingsSuccess({
                          txIds: events.map(event => event.id)
                        }));
                      }
                    })
                  });
              }
            })
            .catch(err => {
              console.error(err, '**** searchAlgoliaData **** => err');
            });
        } catch (error) {
          console.error(error, '**** searchAlgoliaData **** => error');
          return;
        }
      }

      return currentUser;
    })
    .catch(e => {
      console.error(e, '**** **** => e');
      dispatch(queryListingsError(storableError(e)));
    });
};
